import { render, staticRenderFns } from "./round1.vue?vue&type=template&id=01cd53d3&"
import script from "./round1.vue?vue&type=script&lang=js&"
export * from "./round1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "round1.vue"
export default component.exports