import { render, staticRenderFns } from "./blocks1.vue?vue&type=template&id=7987b524&"
import script from "./blocks1.vue?vue&type=script&lang=js&"
export * from "./blocks1.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "blocks1.vue"
export default component.exports