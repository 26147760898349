import { render, staticRenderFns } from "./blinds3d.vue?vue&type=template&id=2260eed3&"
import script from "./blinds3d.vue?vue&type=script&lang=js&"
export * from "./blinds3d.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "blinds3d.vue"
export default component.exports